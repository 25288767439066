@font-face { font-family: 'Camel'; src: url('../fonts/Camel_2.0_Font_Camel_Bold-_1_.woff') format('woff');  }
@font-face { font-family: 'Graphic'; src: url('../fonts/GraphikBold.woff') format('woff');  }
$mainColor: rgba(252, 229, 0, 1);
:root {
  --width: 100vw;
  --height: 100vh;
}
* { margin:0; padding:0; } /* to remove the top and left whitespace */
.camel__font{
  font-family: Camel, serif;
}
body{
  font-family: Graphic, serif;
  &.inactive{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $mainColor;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
  }
}
html, body {
  overscroll-behavior: none;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
}
.main{
  background: url('../images/niebo.png')no-repeat 50% 50% /cover;
  .score__bar{
    background: linear-gradient(180deg, rgba(111,206,242,1) 0%, rgba(16,140,192,1) 100%);
    width: 100%;
    top: 0;
    position: relative;
    height: 70px;
    #score,#lives{
      background: #003d72;
      border-radius: 30px;
      padding: 0 25px;
      display: inline-block;
      position: absolute;
      right: 20%;
      top: 50%;
      -webkit-transform: translate(0,-50%);
      transform: translate(0,-50%);
      color: #fff;
      font-size: 1.3em;
    }
    #lives{
      display: none;
      right: unset;
      left: 20%;
      width: 70px;
      justify-content: flex-start;
      max-height: 40%;
      img{
        width: 31%;
        margin: 0 2% 0 0;
        height: 100%;
      }
    }
  }
  #display{
    position: absolute;
    z-index: 1000;
    top: 60%;
    display: flex;
    justify-content: center;
    width: var(--width);
    #start{
      @media only screen and (max-width: 420px) {
        max-height: 60px;
      };
      height: auto;
      width: 40%;
      cursor: pointer;
    }
  }
  .background__images{
    .city{
      width: 100%;
      position: absolute;
      bottom: 0;
      z-index: -1;
    }
    .pylons{
      .pylon{
        width: 3.5%;
        position: absolute;
        height: 100%;
        background: url('../images/FRAGMENT_PYLON.png')repeat 50% 50% /cover;
        z-index: -2;
        left: 0;
        .apple__neon,.mint__neon{
          position: absolute;
          z-index: 10;
          top: 17%;
        }
        .neon_active{
          opacity: 0;
          transition:.5s linear;
          &.active{
            opacity: 1;
          }
        }
        .apple__neon{
          width: 425%;
          left: 40%;
        }
        .mint__neon{
          width: 500%;
          right: 40%;
        }
        &:nth-of-type(2){
          left: unset;
          right: 0;
        }
      }
    }
  }
}
#main {
  position: relative;
  margin: 0 auto;
  z-index: 30;
  width: var(--width);
}
#background{
  width: var(--width);
  height: var(--height);
}
canvas {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
}

.intro__movie{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 150;
  background-color: $mainColor;
  #video{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 10;
  }
  #video2{
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 5;
  }
}
.before__cards-start{
  z-index: 50;
  background: url('../images/niebo.png')no-repeat 50% 50% /cover;
  .city{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .start__button{
    width: 50%;
    bottom: 20%;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    cursor: pointer;
    z-index: 100;
    position: absolute;
    @media only screen and (max-width: 420px) {
      bottom: 12%;
    };
    @media only screen and (min-height: 750px) {
      bottom: 12%;
    };
    @media only screen and (min-width: 600px) {
      width: 35%;
    };
  }
  .top__icons{
    width: 100%;
  }
  .logo{
    width: 100%;
    @media only screen and (min-height: 700px) and (max-width: 600px) {
      margin: 15% 0 0 0;
    };
    @media only screen and (min-width: 600px) {
      width: 80%;
      position: relative;
      left: 50%;
      -webkit-transform: translate(-50%,0);
      transform: translate(-50%,0);
    }
  }
  .ball{
    position: absolute;
    top: 19%;
    left: 0;
    z-index: -1;
    width: 70%;
  }
}
.before__cards-info{
  z-index: 40;
  background: url('../images/II_TLO.png')no-repeat 50% 50% /cover;
  .text{
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    width: 75%;
    margin: 5% 0 0 2.5%;
    z-index: 50;
    ol{
      @media only screen and (max-height: 470px) and (min-width: 320px) {
        font-size: 0.9em;
      };
      @media only screen and (min-height: 600px) and (min-width: 375px) {
        font-size: 1.1em;
      };
      @media only screen and (min-height: 800px) and (min-width: 400px) {
        font-size: 1.3em;
      };
      @media only screen and (min-height: 900px) and (min-width: 700px) {
        font-size: 1.8em;
      };
      li{
        position: relative;
        &::marker{
          font-size: 1.5em;
          color: #fce601;
          font-family: Camel, serif;
        }
        @media only screen and (min-height: 600px) and (min-width: 375px) {
          line-height: 1.7em;
        };
        color: #FFF;
        font-size: .9em;
        line-height: 1.5em;
        span{
          color: #fce601;
        }
      }
    }
    @media only screen and (min-width: 600px) {
      margin: 5% 0 0 2.5%;
    };
  }
  .header{
    width: 65%;
    margin: 10% 0 0 0;
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    @media only screen and (min-width: 600px) {
      width: 60%;
      margin: 5% 0 0 0;
    };
  }
  .piramid{
    width: 40%;
    position: absolute;
    top: 18%;
    right: -7%;
    @media only screen and (min-width: 350px) {
      top: 30%;
    };
    @media only screen and (min-width: 600px) {
      top: 18%;
    };
    @media only screen and (min-width: 750px) {
      top: 13%;
    };
  }
  .button{
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%,0);
    transform: translate(-50%,0);
    width: 50%;
    cursor: pointer;
    bottom: 20%;
    @media only screen and (max-width: 420px) {
      bottom: 10%;
    };
    @media only screen and (min-width: 600px) {
      bottom: 15%;
      width: 40%;
    };
    @media only screen and (min-width: 750px) {
      bottom: 4%;
    };
  }
}
.before__cards-start,.before__cards-info,.after__cards-win{
  position: absolute;
  top: 0;
  width: var(--width);
  height: var(--height);
  background-color: aliceblue;
  img{
    width: 100%;
  }
}
.after__cards-win{
  z-index: 20;
  background: url('../images/IV_TLO.png')no-repeat 50% 50% /cover;
  .content{
    &__container{
      position: relative;
      height: 100%;
      left: 50%;
      -webkit-transform: translate(-50%,0);
      transform: translate(-50%,0);
      &-border{
        position: relative;
        width: 85%;
        left: 50%;
        top: 45%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        @media only screen and (min-height: 900px) and (min-width: 700px) {
          width: 75%;
          top: 42%;
        };
        img{
          &.border{
            position: relative;
            width: 100%;
          }
        }
      }
      &-stuff{
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        position: absolute;
        .apla{
          position: absolute;
          width: 82%;
          top: 12%;
          left: 50%;
          -webkit-transform: translate(-50%,0);
          transform: translate(-50%,0);
          img{
            width: 100%;
            position: static;
          }
          &__container{
            position: absolute;
            height: 100%;
            width: 90%;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%,0);
            transform: translate(-50%,0);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            font-size: 1.5em;
            @media only screen and (min-height: 900px) and (min-width: 700px) {
              font-size: 2.5em;
            };
            @media only screen and (max-width: 400px) {
              font-size: 1.2em;
            };
            p{
              text-transform: uppercase;
              color: #FFF;
            }
            .camel__font{
              color: $mainColor;
            }
            .score{
              margin: 5% 0;
              font-size: 2em;
            }
            img{
              position: absolute;
              width: 38%;
              right: -21%;
              top: 73%;
            }
          }
        }
        .info{
          position: absolute;
          bottom: 5%;
          right: 5%;
          width: 55%;
          height: 31%;
          font-size: 1.3em;
          line-height: 1.2em;
          @media only screen and (max-width: 400px) {
            font-size: 1.1em;
          };

          @media only screen and (min-height: 900px) and (min-width: 700px) {
            font-size: 2.4em;
          };
          p{
            text-transform: uppercase;
          }
          .text{
            color: #FFF;
          }
          .taste{
            color: $mainColor;
            background-color: #004471;
            border-radius: 1em;
            margin: 0 -.5em;
            padding: .1em .5em;
            line-height: 1.2em;
            display: inline-block;
          }
        }
        img{
          position: absolute;
          &.cigarets{
            width: 46%;
            bottom: -16%;
            left: -5%;
          }
        }
      }
    }
  }
}






